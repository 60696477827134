import React, { Suspense, lazy } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import CookieConsent from 'react-cookie-consent';

// Components
const MainNavbarMenu = lazy(() => import('./Components/Navbar/MainNavbarMenu'));
const CustomNavbar = lazy(() => import('./Components/Navbar/CustomNavbar'));
const HfFooter = lazy(() => import('./Components/HfFooter/HfFooter'));

// Pages - HansaFlex Belgium
const ContactUs = lazy(() => import('./Pages/HansaFlexBelgiumPages/ContactUs'));
const AboutHFBelgium = lazy(() => import('./Pages/HansaFlexBelgiumPages/AboutHFBelgium'));
const Journey = lazy(() => import('./Pages/HansaFlexBelgiumPages/Journey'));
const Management = lazy(() => import('./Pages/HansaFlexBelgiumPages/Management'));
const Certificates = lazy(() => import('./Pages/HansaFlexBelgiumPages/Certificates'));

// Pages - HansaFlex Group
const AnnualReports = lazy(() => import('./Pages/HansaFlexGroupPages/AnnualReports'));
const HansaFlexGroupPage = lazy(() => import('./Pages/HansaFlexGroupPages/HansaFlexGroupPage'));
const GroupManagement = lazy(() => import('./Pages/HansaFlexGroupPages/GroupManagement'));
const GroupHistory = lazy(() => import('./Pages/HansaFlexGroupPages/GroupHistory'));
const GroupMissionStatement = lazy(() => import('./Pages/HansaFlexGroupPages/GroupMissionStatement'));
const SixtyYearOfHansaflex = lazy(() => import('./Pages/HansaFlexGroupPages/SixtyYearOfHansaFlex'));

// Pages - News and Events
const Wholesale = lazy(() => import('./Pages/NewsAndEvents/Wholesale'));
const ElectraMining = lazy(() => import('./Pages/NewsAndEvents/Fisheye'));

// Pages - General
const PrivayPolicyPage = lazy(() => import('./Pages/PrivacyPolicy'));
const TermsofPurchase = lazy(() => import('./Pages/TermsofPurchase'));
const Disclaimer = lazy(() => import('./Pages/Disclaimer'));
const Imprint = lazy(() => import('./Pages/Imprint'));
const HomePage = lazy(() => import('./Pages/HomePage'));
const ProductsPage = lazy(() => import('./Pages/ProductsPage'));
const Careers = lazy(() => import('./Pages/Careers'));

// Pages - Training
const Training = lazy(() => import('./Pages/Training/Training'));

// Products and services pages
const HosesAndHoseLines = lazy(() => import('./Pages/ProductPages/HosesAndHoseLines/HosesAndHoseLines'));
const HydraulicHose = lazy(() => import('./Pages/ProductPages/HosesAndHoseLines/HydraulicHose'));
const MetalHoses = lazy(() => import('./Pages/ProductPages/HosesAndHoseLines/MetalHoses'));
const IndustrialHoses = lazy(() => import('./Pages/ProductPages/HosesAndHoseLines/IndustrialHoses'));
const PerformedHoses = lazy(() => import('./Pages/ProductPages/HosesAndHoseLines/PerformedHoses'));
const ExtremelyHighPerformanceHoses = lazy(() => import('./Pages/ProductPages/HosesAndHoseLines/ExtremelyHighPerformanceHoses'));
const HoseLineConfigurator = lazy(() => import('./Pages/ProductPages/HosesAndHoseLines/HoseLineConfigurator'));
const PtfeHoses = lazy(() => import('./Pages/ProductPages/HosesAndHoseLines/PtfeHoses'));
const Accessories = lazy(() => import('./Pages/ProductPages/HosesAndHoseLines/Accessories'));
const DriveAndControlTechnology = lazy(() => import('./Pages/ProductPages/DriveAndControlTechnology/DriveAndControlTechnology'));
const Pumps = lazy(() => import('./Pages/ProductPages/DriveAndControlTechnology/Pumps'));
const HydraulicMotor = lazy(() => import('./Pages/ProductPages/DriveAndControlTechnology/HydraulicMotor'));
const Cylinder = lazy(() => import('./Pages/ProductPages/DriveAndControlTechnology/Cylinder'));
const SwitchesAndSensors = lazy(() => import('./Pages/ProductPages/DriveAndControlTechnology/SwitchesAndSensors'));
const ValveTechnology = lazy(() => import('./Pages/ProductPages/DriveAndControlTechnology/ValveTechnology/ValveTechnology'));
const PipelineInstallationValve = lazy(() => import('./Pages/ProductPages/DriveAndControlTechnology/ValveTechnology/PipelineInstallationValve'));
const CartridgeValves = lazy(() => import('./Pages/ProductPages/DriveAndControlTechnology/ValveTechnology/CartridgeValves'));
const CetopValves = lazy(() => import('./Pages/ProductPages/DriveAndControlTechnology/ValveTechnology/CetopValves'));
const MeasuringTechnology = lazy(() => import('./Pages/ProductPages/DriveAndControlTechnology/MeasuringTechnology'));
const SevenHundredBarHydraulics = lazy(() => import('./Pages/ProductPages/DriveAndControlTechnology/SevenHundredBarHydraulics'));
const PressureAccumulators = lazy(() => import('./Pages/ProductPages/DriveAndControlTechnology/PressureAccumulators'));
const SealingTechnology = lazy(() => import('./Pages/ProductPages/SealingTechnology'));
const Filtration = lazy(() => import('./Pages/ProductPages/Filtration'));
const Pneumatics = lazy(() => import('./Pages/ProductPages/Pneumatics'));
const TechnicalInformation = lazy(() => import('./Pages/ProductPages/TechnicalInformation'));
const Approvals = lazy(() => import('./Pages/ProductPages/Approvals'));
const Catalogues = lazy(() => import('./Pages/ProductPages/Catalogues'));
const HydraulicConnectionTechnology = lazy(() => import('./Pages/ProductPages/HydraulicConnectionTechnology/HydraulicConnectionTechnology'));
const HoseFittings = lazy(() => import('./Pages/ProductPages/HydraulicConnectionTechnology/HoseFittings'));
const PipeFittings = lazy(() => import('./Pages/ProductPages/HydraulicConnectionTechnology/PipeFittings'));
const Adapters = lazy(() => import('./Pages/ProductPages/HydraulicConnectionTechnology/Adapters'));
const Couplings = lazy(() => import('./Pages/ProductPages/HydraulicConnectionTechnology/Couplings'));
const BallValves = lazy(() => import('./Pages/ProductPages/HydraulicConnectionTechnology/BallValves'));
const Flanges = lazy(() => import('./Pages/ProductPages/HydraulicConnectionTechnology/Flanges'));
const Pipelines = lazy(() => import('./Pages/ProductPages/Pipelines/Pipelines'));
const Pipebends = lazy(() => import('./Pages/ProductPages/Pipelines/Pipebends'));
const ExpansionJoints = lazy(() => import('./Pages/ProductPages/Pipelines/ExpansionJoints'));
const PipeendProcessing = lazy(() => import('./Pages/ProductPages/Pipelines/PipeendProcessing'));
const HoseManagement = lazy(() => import('./Pages/ServicesPages/PreventativeMaintainance/HoseManagement/HoseManagement'));
const FluidService = lazy(() => import('./Pages/ServicesPages/FluidManagement/FluidService'));
const MobileService = lazy(() => import('./Pages/ServicesPages/MobileRapidHydraulicService'));
const HydraulicAcademy = lazy(() => import('./Pages/ServicesPages/HydraulicAccademy'));
const OilAnalysis = lazy(() => import('./Pages/ServicesPages/FluidManagement/OilAnalysis'));
const OilFilteringAndCleaning = lazy(() => import('./Pages/ServicesPages/FluidManagement/OilFilteringAndCleaning'));
const AssemblyAndInstallation = lazy(() => import('./Pages/ServicesPages/AssembellyAndInstallation/AssemblyAndInstallation'));
const ModernisationAndOptimisation = lazy(() => import('./Pages/ServicesPages/AssembellyAndInstallation/ModernisationAndOptimisation'));
const PlantProjectPlanningAndManagement = lazy(() => import('./Pages/ServicesPages/AssembellyAndInstallation/PlantProjectPlanningAndManagement'));
const InstallationOfPipeAndHoseLineSystems = lazy(() => import('./Pages/ServicesPages/AssembellyAndInstallation/InstallationOfPipeAndHoseLineSystems'));
const PreventataiveMaintainance = lazy(() => import('./Pages/ServicesPages/PreventativeMaintainance/PreventataiveMaintainance'));
const ServicePackages = lazy(() => import('./Pages/ServicesPages/PreventativeMaintainance/HoseManagement/ServicePackages'));
const XCodeIdentification = lazy(() => import('./Pages/ServicesPages/PreventativeMaintainance/HoseManagement/XCodeIdentification'));
const XCodeManager = lazy(() => import('./Pages/ServicesPages/PreventativeMaintainance/HoseManagement/XCodeManager/XCodeManager'));
const Licenses = lazy(() => import('./Pages/ServicesPages/PreventativeMaintainance/HoseManagement/XCodeManager/Licenses'));
const Inspection = lazy(() => import('./Pages/ServicesPages/PreventativeMaintainance/Inspection/Inspection'));
const IndustrialHoseInspection = lazy(() => import('./Pages/ServicesPages/PreventativeMaintainance/Inspection/IndustrialHoseInspection'));
const DguvInspection = lazy(() => import('./Pages/ServicesPages/PreventativeMaintainance/Inspection/DguvInspection'));
const LiftingPlatformsAndLoadingBridges = lazy(() => import('./Pages/ServicesPages/PreventativeMaintainance/Inspection/LiftingPlatformsAndLoadingBridges'));
const RepairAndOverhaul = lazy(() => import('./Pages/ServicesPages/RepairAndOverhaul/RepairAndOverhaul'));
const PressureAccumulatorsAndAssociatedSystems = lazy(() => import('./Pages/ServicesPages/RepairAndOverhaul/PressureAccumulatorsAndAssociatedSystems'));
const HydraulicCylinderRepair = lazy(() => import('./Pages/ServicesPages/RepairAndOverhaul/HydraulicCylinderRepair'));
const DigitalServices = lazy(() => import('./Pages/ServicesPages/Digital Services/DigitalServices'));
const EDIandBMEcat = lazy(() => import('./Pages/ServicesPages/Digital Services/EDIandBMEcat'));
const Scannersolutions = lazy(() => import('./Pages/ServicesPages/Digital Services/Scannersolutions'));
const Production = lazy(() => import('./Pages/ServicesPages/Production/Production'));
const CustomisedAssembly = lazy(() => import('./Pages/ServicesPages/Production/CustomisedAssembly'));
const SeriesProduction = lazy(() => import('./Pages/ServicesPages/Production/SeriesProduction'));
const Logisticssolutions = lazy(() => import('./Pages/ServicesPages/Logistics solutions/Logisticssolutions'));
const Warehousemanagement = lazy(() => import('./Pages/ServicesPages/Logistics solutions/Warehousemanagement'));
const Kanbandelivery = lazy(() => import('./Pages/ServicesPages/Logistics solutions/Kanbandelivery'));
const Kitting = lazy(() => import('./Pages/ServicesPages/Logistics solutions/Kitting'));
const Hydraulicworkshopcontainers = lazy(() => import('./Pages/ServicesPages/Logistics solutions/Hydraulicworkshopcontainers'));
const Worksinworks = lazy(() => import('./Pages/ServicesPages/Logistics solutions/Worksinworks'));
const Engineering = lazy(() => import('./Pages/ServicesPages/Enginnering/Engineering'));
const EngineeredbyHansaflex = lazy(() => import('./Pages/ServicesPages/Enginnering/EngineeredbyHansaflex'));
const Hydraulicunit = lazy(() => import('./Pages/ServicesPages/Enginnering/Hydraulicunit'));
const Prototypemanufacture = lazy(() => import('./Pages/ServicesPages/Enginnering/Prototypemanufacture'));
const Pressureaccumulatorinspection = lazy(() => import('./Pages/ServicesPages/Pressureaccumulatorinspection'));
const MobileRapidHydraulicService = lazy(() => import('./Pages/ServicesPages/MobileRapidHydraulicService'));


function App() {

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className='primary-navbar'>
            <CustomNavbar />
          </div>

        </header>
        <div className='secondary-navbar sticky-top '>
          <MainNavbarMenu />
        </div>
        <Suspense fallback={
          <div className="SpinnerForLoading" >
            <Spinner animation="grow" role="status">
              <span className=""></span>
            </Spinner>
          </div>}>
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/who-we-are" exact component={AboutHFBelgium} />

            {/* HF Portugal Pages */}
            <Route path="/management" component={Management} />
            <Route path="/journey" component={Journey} />
            <Route path="/certificates" component={Certificates} />
            <Route path="/careers" component={Careers} />
            <Route path="/hansa-flex-india-privacy-policy" component={PrivayPolicyPage} />
            <Route path="/hansa-flex-TermsofPurchase" component={TermsofPurchase} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/hansa-flex-Imprint" component={Imprint} />
            <Route path="/terms-and-conditions" component={Disclaimer} />


            {/* HF Group Pages */}
            <Route path="/hf-group" component={HansaFlexGroupPage} />
            <Route path="/hf-group-management" component={GroupManagement} />
            <Route path="/hf-group-history" component={GroupHistory} />
            <Route path="/hf-group-annual-reports" component={AnnualReports} />
            <Route path="/hf-group-mission-statement" component={GroupMissionStatement} />
            <Route path="/hf-60-years-of-hansaflex" component={SixtyYearOfHansaflex} />


            {/* HF news and events Pages */}
            <Route path="/Wholesale" component={Wholesale} />
            <Route path="/working-with-fisheye" component={ElectraMining} />
            <Route path="/management" component={Management} />

            <Route path="/training" component={Training} />


            {/* HF Products Pages */}
            <Route path="/products" component={ProductsPage} />
            <Route path="/hoses-and-hose-lines" component={HosesAndHoseLines} />
            <Route path="/hose-line-config" component={HoseLineConfigurator} />
            <Route path="/hydraulic-hoses" component={HydraulicHose} />
            <Route path="/metal-hoses" component={MetalHoses} />
            <Route path="/Industrial-hoses" component={IndustrialHoses} />
            <Route path="/performed-hoses" component={PerformedHoses} />
            <Route path="/ext-high-pfm-hoses" component={ExtremelyHighPerformanceHoses} />
            <Route path="/Ptfe-hoses" component={PtfeHoses} />
            <Route path="/hose-accessories" component={Accessories} />

            <Route path="/drive-and-control-tech" component={DriveAndControlTechnology} />
            <Route path="/pumps" component={Pumps} />
            <Route path="/hydraulic-motor" component={HydraulicMotor} />
            <Route path="/cylinder" component={Cylinder} />
            <Route path="/switches-and-sensors" component={SwitchesAndSensors} />
            <Route path="/valve-tech" component={ValveTechnology} />
            <Route path="/pipeline-installation-valve" component={PipelineInstallationValve} />
            <Route path="/cartridge-valve" component={CartridgeValves} />
            <Route path="/cetop-valve" component={CetopValves} />
            <Route path="/measuring-tech" component={MeasuringTechnology} />
            <Route path="/seven-hundred-hydraulics" component={SevenHundredBarHydraulics} />
            <Route path="/pressure-accumulators" component={PressureAccumulators} />

            <Route path="/hydraulic-connection-technology" component={HydraulicConnectionTechnology} />
            <Route path="/hose-fitting" component={HoseFittings} />
            <Route path="/pipe-fitting" component={PipeFittings} />
            <Route path="/adapters" component={Adapters} />
            <Route path="/coupling" component={Couplings} />
            <Route path="/ball-valves" component={BallValves} />
            <Route path="/flanges" component={Flanges} />

            <Route path="/sealing-tech" component={SealingTechnology} />
            <Route path="/filtration" component={Filtration} />
            <Route path="/pneumatics" component={Pneumatics} />
            <Route path="/technical-information" component={TechnicalInformation} />
            <Route path="/approvals" component={Approvals} />
            <Route path="/catalogues" component={Catalogues} />

            <Route path="/pipelines" component={Pipelines} />
            <Route path="/pipebends" component={Pipebends} />
            <Route path="/pipeend-processing" component={PipeendProcessing} />
            <Route path="/Expansionjoints" component={ExpansionJoints} />

            {/* HF Services Pages */}
            <Route path="/hose-management" component={HoseManagement} />
            {/* <Route path="/mobile-rapid-hydraulic-service" component={MobileRapidHydraulicService} /> */}
            <Route path="/hydraulic-academy" component={HydraulicAcademy} />

            <Route path="/fluid-service" component={FluidService} />
            <Route path="/oil-analysis" component={OilAnalysis} />
            <Route path="/oil-filtering-and-cleaning" component={OilFilteringAndCleaning} />

            <Route path="/assembly-and-installation" component={AssemblyAndInstallation} />
            <Route path="/modernisation-and-optimisation" component={ModernisationAndOptimisation} />
            <Route path="/plant-project-planning-and-management" component={PlantProjectPlanningAndManagement} />
            <Route path="/installation-of-pipe-and-hoseline-systems" component={InstallationOfPipeAndHoseLineSystems} />

            <Route path="/preventative-maintainance" component={PreventataiveMaintainance} />
            <Route path="/service-packages" component={ServicePackages} />
            <Route path="/x-code-hose-identification-system" component={XCodeIdentification} />
            <Route path="/x-code-manager" component={XCodeManager} />
            <Route path="/licenses" component={Licenses} />
            <Route path="/inspection" component={Inspection} />
            <Route path="/industrial-hose-inspection" component={IndustrialHoseInspection} />
            <Route path="/dguv-inspection" component={DguvInspection} />
            <Route path="/lifting-platforms-and-loading-bridges" component={LiftingPlatformsAndLoadingBridges} />

            <Route path="/repair-and-overhaul" component={RepairAndOverhaul} />
            <Route path="/hydraulic-cyclinder-repair" component={HydraulicCylinderRepair} />
            <Route path="/pressure-accumulators-and-associated-systems" component={PressureAccumulatorsAndAssociatedSystems} />

            <Route path="/DigitalServices" component={DigitalServices} />
            <Route path="/EDIandBMEcat" component={EDIandBMEcat} />
            <Route path="/Scannersolutions" component={Scannersolutions} />
            <Route path="/Production" component={Production} />
            <Route path="/Customisedassembly" component={CustomisedAssembly} />
            <Route path="/SeriesProduction" component={SeriesProduction} />

            <Route path="/Logisticssolutions" component={Logisticssolutions} />
            <Route path="/Warehousemanagement" component={Warehousemanagement} />
            <Route path="/Kanbandelivery" component={Kanbandelivery} />
            <Route path="/Kitting" component={Kitting} />
            <Route path="/Hydraulicworkshopcontainers" component={Hydraulicworkshopcontainers} />
            <Route path="/Worksinworks" component={Worksinworks} />

            <Route path="/Engineering" component={Engineering} />
            <Route path="/EngineeredbyHansaflex" component={EngineeredbyHansaflex} />
            <Route path="/Hydraulicunit" component={Hydraulicunit} />
            <Route path="/Prototypemanufacture" component={Prototypemanufacture} />

            <Route path="/Pressureaccumulatorinspection" component={Pressureaccumulatorinspection} />

          </Switch>
        </Suspense>
        <div>
          <HfFooter />
        </div>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="HFCookieConsent"
          style={{ background: '#4571a2', color: '#fff', textAlign: 'center' }}
          buttonStyle={{ color: '#fff', backgroundColor: '#d40606', fontSize: '14px' }}
          expires={365}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      </div>
    </Router>
  );
}

export default App;
